import { v as bvVar } from 'bv';
import { t } from 'bv-i18n';

const ContactFooter = () => (
  <>
    <span className="contact-error-title">{t('contact_us')}</span>
    <a className="contact-error-email" href={`mailto:${bvVar('helpEmail')}`}>
      {bvVar('helpEmail')}
    </a>
  </>
);

export default ContactFooter;
