import { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { v as bvVar } from 'bv';
import { ContentLayout } from 'bv-components';
import { setPrerenderReady } from 'bv-services/seo';
import ContactFooter from './components/contact_footer';
import ErrorItems from './components/error_items';
import ErrorMessage from './components/error_message';

const ErrorPageView = ({ showAdditionalButtons, showContentLayout }) => {
  useEffect(() => {
    document.querySelector('meta[name="prerender-status-code"]').setAttribute('content', '404');
    window.prerenderReady = true;
    setPrerenderReady();
  }, []);

  const Wrapper = showContentLayout ? ContentLayout : Fragment;

  return (
    <Wrapper showTabs>
      <div className="error-page-wrapper">
        <ErrorMessage showAdditionalButtons={showAdditionalButtons} />
        <ErrorItems />
        {bvVar('helpEmail') && <ContactFooter />}
      </div>
    </Wrapper>
  );
};

ErrorPageView.propTypes = {
  showAdditionalButtons: PropTypes.func,
  showContentLayout: PropTypes.bool,
};

ErrorPageView.defaultProps = {
  showAdditionalButtons: () => {},
  showContentLayout: false,
};

export default ErrorPageView;
