import PropTypes from 'prop-types';
import { Button } from 'bv-components';
import { t } from 'bv-i18n';
import { v as bvVar } from 'bv';

const ErrorMessage = ({ showAdditionalButtons }) => (
  <div className="site-error-message">
    <h1 className="bvs-h1 error-page-header">
      {`${bvVar('httpStatus') !== 200 ? bvVar('httpStatus') : 404} ${t('javascript.error_page.error')}`}
    </h1>
    <span className="error_bv_icon" />
    <p>{t('javascript.error_page.page_unavailable')}</p>
    <Button to="/" primary className="error-page__button">
      {t('javascript.home')}
    </Button>
    {' '}
    {showAdditionalButtons()}
  </div>
);

ErrorMessage.propTypes = {
  showAdditionalButtons: PropTypes.func,
};

ErrorMessage.defaultProps = {
  showAdditionalButtons: () => {},
};

export default ErrorMessage;
