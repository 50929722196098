import { cmsAssetPath } from 'bv';
import { Link } from 'bv-components';

const items = window.VCSTATE.ERROR_PAGE_ITEMS || [];

const ErrorItems = () => items.length > 0 && (
  <div className="error-cat-container clearfix">
    {items.map((item) => (
      <Link
        to={item.url.substring(item.url.indexOf('/', 1))}
        className="error-cat"
      >
        <div
          className="error_page_item_image"
          style={{ backgroundImage: `url(${cmsAssetPath(item.image_url)})` }}
        />
        <span>{item.label}</span>
      </Link>
    ))}
  </div>
);

export default ErrorItems;
